import React, { Component } from 'react'

class Sommerjobb extends Component {
  componentDidMount() {
    window.location.replace(
      'https://candidate.hr-manager.net/ApplicationInit.aspx?_sp=5ce993cc-19a5-4f5f-99d6-5fe5c8142271.1725630033765&cid=1889&ProjectId=147701&DepartmentId=21801&MediaId=4737'
    )
  }

  render() {
    return <div />
  }
}

export default Sommerjobb
